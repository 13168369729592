import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setSnDashboardData } from "redux/actions/sndashboard/sndashboard.actions";
import { getActiveServicesCountSuccess } from "redux/actions/allservices/allservices.actions";
import { selectors as snDashboardSelectors } from "redux/reducers//sndashboard/sndashboard_reducer";
import { Table } from '../../components/Table';
import { ErrorComponent } from '../../components/shared/ErrorComponent';
import { Spinner } from '../../components/Spinner';
import "./ServiceNowDashboard.scss";
import { SN_DASHBOARD } from "redux/constants";

export const ServiceNowDashboard = (props) => {
  const [tableSettings, setTableSettings] = useState({
    sort: {
      "column": 'requestedDate',
      "direction": 'desc'
    }
  });
  const { Btoken, hrid } = props;
  const dispatch = useDispatch();

  const {
    snDashboardData,
    isSnDashboardLoading,
    isError,
    isSnDashboardAPITakingTime,
    snDashboardStatusList
  } = useSelector(
    (state) => ({
      snDashboardData: snDashboardSelectors.getSnDashboardData(state),
      isSnDashboardLoading: snDashboardSelectors.getSnDashboardDataLoader(state),
      isError: snDashboardSelectors.getError(state),
      isSnDashboardAPITakingTime: snDashboardSelectors.getSnDashboardAPITakingTime(state),
      snDashboardStatusList: snDashboardSelectors.getSnDashboardStatusList(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SN_DASHBOARD.GET_SN_DASHBOARD_DATA, payload: {
        snDashboardStatusList: []
      }
    });
  }, []);

  useEffect(() => {
    const ts = {
      sort: {
        "column": 'requestedDate',
        "direction": 'desc'
      }
    }
    setTableSettings(ts);
  }, []);

  useEffect(() => {
    sortAndDispatchDashboardData(tableSettings);
  }, [isSnDashboardLoading])

  const handleFilterClick = (e, snDashboardStatuses) => {
    dispatch({
      type: SN_DASHBOARD.GET_SN_DASHBOARD_DATA, payload: {
        snDashboardStatusList: snDashboardStatuses
      }
    });
  }

  const setActiveServiceCount = (data) => {
    dispatch(getActiveServicesCountSuccess(data));
  }
  const handleSortIconClick = (e, data) => {
    e.stopPropagation();
    let tableSettings1;
    if (tableSettings.sort.column == data.value) {
      tableSettings1 = {
        sort: {
          "column": data.value,
          "direction": tableSettings.sort.direction == 'asc' ? 'desc' : 'asc'
        }
      }
      setTableSettings(tableSettings1);
    } else {
      const shouldSortDesc = data.value === "lastUpdated" || data.value === "requestedDate";
      const direction = shouldSortDesc ? 'desc' : 'asc';
      tableSettings1 = {
        sort: {
          "column": data.value,
          direction
        }
      }
      setTableSettings(tableSettings1);
    }
    sortAndDispatchDashboardData(tableSettings1);
  };

  const sortAndDispatchDashboardData = (tableSettings1) => {
    const loadingDoneWithoutError = !isSnDashboardLoading && !isError;
    if (loadingDoneWithoutError) {
      const key = tableSettings1.sort.column;
      const sortAsc = tableSettings1.sort.direction == 'asc';
      const snDashboardData1 = {
        ServiceRequests: snDashboardData.ServiceRequests.sort((a, b) => {
          return sortAsc ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
        })
      }
      dispatch(setSnDashboardData(snDashboardData1));
    }
  }

  const filterList = ["All", "Open", "Work in progress", "Complete", "Cancelled", "Acknowledged", "Assigned", "Awaiting Third Party", "Delivered", "New", "Pre-assigned"]

  const column = [
    { heading: 'Status', value: 'status', className: "status", filter: true, filterList, width: "130px" },
    { heading: 'Service Offering', value: 'serviceOffering', tooltip: true, charLength: 75, sort: true, width: "330px" },
    { heading: 'Last Updated', value: 'lastUpdated', type: 'date', sort: true, width: "240px" },
    { heading: 'Requested Date', value: 'requestedDate', type: 'date', sort: true, width: "150px" },
    { heading: 'Last Comment', value: 'lastComment', tooltip: true, charLength: 180, width: "304px" },
  ];
  if (!isError) {
    return (
      <div className="sn-dashboard">
        {isSnDashboardLoading && <Spinner isSnDashboardAPITakingTime={isSnDashboardAPITakingTime} />}
        {
          !isSnDashboardLoading && <Table
            className="cts-table"
            data={snDashboardData.ServiceRequests}
            handleFilterClick={handleFilterClick}
            column={column}
            tableSettings={tableSettings}
            handleSortIconClick={handleSortIconClick}
            setActiveServiceCount={setActiveServiceCount}
          />
        }
      </div >
    );
  } else {
    return <div className="sn-dashboard"><ErrorComponent /></div>
  }

};
