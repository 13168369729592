import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setSnDashboardData, setSnDashboardStatusList } from "redux/actions/sndashboard/sndashboard.actions";
import { selectors as snDashboardSelectors } from "redux/reducers//sndashboard/sndashboard_reducer";
import Image from "components/shared/Image";
import SortBlackIcon from "assets/images/sort_black.svg";
import SortAscendingIcon from "assets/images/sort_ascending.svg";
import SortDescendingIcon from "assets/images/sort_descending.svg";
import GreenDownIcon from "assets/images/green_down_arrow.svg";
import NoRecordFound from "assets/images/no_record_found.svg";
import EmailIcon from "assets/images/email_icon.svg";
import SlackIcon from "assets/images/slack_icon.svg";
import { Checkbox, Popup } from '@kdshared/enrich-ui';
import "./Table.scss";
import { sendAdobeAnaltics } from "utils/helpers/helpers";
import { PAGES } from "../../utils/analytics/analytics_constants";

export const Table = ({ className, data, column, handleSortIconClick, tableSettings, handleFilterClick, setActiveServiceCount }) => {
    return (
        <React.Fragment>
            <div className={className}>
                <TableHeader data={data} column={column} handleSortIconClick={handleSortIconClick} tableSettings={tableSettings} handleFilterClick={handleFilterClick}
                    setActiveServiceCount={setActiveServiceCount} />
                <TableBody column={column} data={data} />
            </div>
            {data.length === 0 && <div className="no-record-found">
                <Image src={NoRecordFound} alt="NoRecordFound" />
                <p>There are no requests for selected status. <br></br>In case you want to change your selection, please use the status filter.</p>
            </div>}
        </React.Fragment>
    )
}

const TableHeader = ({ column, handleSortIconClick, tableSettings, data, handleFilterClick, setActiveServiceCount }) => {
    return (
        <div className={`table-header ${data.length > 5 && "scrollable-table"}`}>
            {
                column.map((item, index) =>
                    <TableHeadItem
                        key={index}
                        item={item}
                        handleSortIconClick={handleSortIconClick}
                        tableSettings={tableSettings}
                        handleFilterClick={handleFilterClick}
                        setActiveServiceCount={setActiveServiceCount}
                    />
                )
            }
        </div>
    )
}

const TableBody = ({ data, column }) => {
    return (
        <div className={data.length > 5 && "scrollable-parent"}>
            <div className="table-body">
                {data.map((item, index) => <TableRow item={item} key={index} column={column} />)}
            </div>
        </div>
    )
}

const TableHeadItem = ({ handleFilterClick, item, handleSortIconClick, tableSettings, setActiveServiceCount }) => {
    const [showFilterContainer, setShowFilterContainer] = useState(false);
    let imageName = 'sort_black.svg';
    let imagePath = SortBlackIcon;
    if (tableSettings.sort.column == item.value) {
        if (tableSettings.sort.direction == 'asc') {
            imagePath = SortAscendingIcon;
            imageName = 'sort_ascending.svg';
        } else {
            imagePath = SortDescendingIcon;
            imageName = 'sort_descending.svg';
        }
    }

    const handleIconClick = async (e, item) => {
        if (item.sort) {
            handleSortIconClick(e, item);
            await sendAdobeAnaltics(null, null, item.heading, "SORT_FILTER_CLICKS", "My Requests", PAGES.CTS_MY_REQUESTS, {
                filterTerm: "Sort",
                filterLabel: "NA"
            })
        }
        if (item.filter) {
            handleFilterIconClick();
        }
    }

    const handleFilterIconClick = (status) => {
        status === false ? setShowFilterContainer(status) : setShowFilterContainer(!showFilterContainer);
    }

    let className = item.sort ? 'sortable-heading' : '';
    className += item.filter ? 'filterable-heading' : '';
    const sortableColumn = tableSettings.sort.column == item.value;
    const ifLast3Columns = item.value === "lastUpdated" || item.value === "requestedDate" || item.value === "lastComment";
    return (
        <div className={`table-head-item ${ifLast3Columns && 'date-column'}`} style={item.width ? { width: item.width } : {}}>
            <span className={className} onClick={(e) => handleIconClick(e, item)}>
                {item.heading}
                {item.sort &&
                    <Image className={`sort-icon ${item.type} ${item.value} ${sortableColumn && 'active'}`}
                        src={imagePath}
                        alt="sort-icon" />

                }
            </span>
            {item.filter && <FilterComponent handleFilterClick={handleFilterClick} filterList={item.filterList} showFilterContainer={showFilterContainer} handleFilterIconClick={handleFilterIconClick} setActiveServiceCount={setActiveServiceCount} />}
        </div>
    )
};
const TableRow = ({ item, column }) => {
    const rowClick = async (event) => {
        if (!event.target.className.includes("contact-link")) {
            item.ticketLink && window.open(item.ticketLink, "_blank");
            await sendAdobeAnaltics(item.ticketId, null, "Ticket ID", "OP_CATEGORY_BUTTON_EVENTS", "My Requests", PAGES.CTS_MY_REQUESTS);
        }
    }
    return (
        <div onClick={rowClick} className={`table-row ${item.displayColor}`}>
            {
                column.map((columnItem, index) =>
                    <TableRowItem
                        key={index}
                        item={item}
                        columnItem={columnItem}
                    />
                )
            }
        </div >
    )
}
const TableRowItem = ({ item, columnItem }) => {
    const itemType = columnItem.value;
    let rowItemValue;
    switch (itemType) {
        case "status":
            rowItemValue = <StatusBox item={item} />;
            break;
        case "serviceOffering":
            rowItemValue = <ServiceOfferingBox item={item} columnItem={columnItem} />;
            break;
        case "lastUpdated":
            rowItemValue = <LastUpdatedBox item={item} />;
            break;
        case "requestedDate":
            rowItemValue = <RequestedDateBox item={item} />;
            break;
        case "lastComment":
            rowItemValue = <LastCommentBox item={item} columnItem={columnItem} />;
            break;
        default:
            break;
    }

    return <div className="table-row-item" style={columnItem.width ? { width: columnItem.width } : {}}> {rowItemValue}</div>;
}

const StatusBox = ({ item }) => {

    return <div className="box status-box">
        <div className="heading ticket-id">
            <span className="row-item-heading">Ticket ID</span>
            <a><span>{item.ticketId}</span></a>
        </div>
        <div className="heading status">
            <span className="row-item-heading second-heading">Status</span>
            <span className="status">{item.status}</span>
        </div>
    </div>
}

const ServiceOfferingBox = ({ item, columnItem }) => {
    return <div className="box service-offering-box">
        <div className="heading ticket-id">
            <span className="row-item-heading">Title</span>
            <span className={`text-value`}>{item.title ? <TooltipComponent columnItem={columnItem} text={item.title} type='title' /> : '-'}</span>
        </div>
        <div className="heading status">
            <span className="row-item-heading second-heading">Service Offering</span>
            <span className={`text-value`}>{item.serviceOffering ? item.serviceOffering : '-'}</span>
        </div>
    </div>
}

const LastUpdatedBox = ({ item }) => {
    return <div className="box last-updated-box">
        <div className="heading ticket-id">
            <span className="row-item-heading">Last Updated</span>
            <span className={`text-value`}>{item.lastUpdated ? getFormattedDate(item.lastUpdated) : '-'}</span>
        </div>
        <div className="heading status">
            <span className="row-item-heading second-heading">Contact</span>
            {item.assignedToHrid ?
                <a rel="noreferrer" href={`${process.env.REACT_APP_PROFILE_LINK}/overview?hrid=${item.assignedToHrid}&source=cts`} target="_blank"><span className="contact-link">{item.assignedToName ? item.assignedToName : '-'}</span></a>
                : <span className='text-value'>{item.assignedToName ? item.assignedToName : '-'}</span>
            }

            <p className="contact-icon-parent">
                {item.email && <a href={`mailto:${item.email}?subject=Status of My Request- ${item.ticketId}`} >
                    <Image className="contact-link" src={EmailIcon} alt="EmailIcon" />
                </a>
                }
                {item.slackId && <a rel="noreferrer" className="slack-link" href={`https://nc-global-bcg.slack.com/app_redirect?channel=${item.slackId}`} target="_blank" >
                    <Image className="contact-link" src={SlackIcon} alt="SlackIcon" />
                </a>}
            </p>
        </div>
    </div>
}

const RequestedDateBox = ({ item }) => {
    return <div className="box requested-date-box">
        <div className="heading ticket-id">
            <span className="row-item-heading">Requested Date</span>
            <span className={`text-value`}>{item.requestedDate ? getFormattedDate(item.requestedDate) : '-'}</span>
        </div>
    </div>
}

const LastCommentBox = ({ item, columnItem }) => {
    return <div className="box last-comment-box">
        <div className="heading ticket-id">
            <span className="row-item-heading">Last Comment</span>
            <span className={`text-value last-comment-text`}>{item.lastComment ? <TooltipComponent columnItem={columnItem} text={item.lastComment} type='lastComment' /> : '-'}</span>
        </div>
    </div>
}

const TooltipComponent = ({ columnItem, text, type }) => {
    let columnValue = text;
    const ifTooltipAndTextLengthExceeded = columnItem.tooltip && text.length > columnItem.charLength;
    const tootipTextRef = useRef(null);
    const [top, setTop] = useState('250px');
    const [left, setLeft] = useState('1250px');
    const [visibility, setVisibility] = useState('hidden');
    const [tootipPosition, setTootipPostion] = useState('top');
    const showToolTip = (event) => {
        const rect = event.target.getBoundingClientRect();
        const height = tootipTextRef.current.offsetHeight;
        let toolTipTop = rect.top - height - 7;
        let toolTipLeft = rect.left - 45;
        if (toolTipTop < 60) {
            setTootipPostion('bottom');
            toolTipTop = rect.top + (type == 'title' ? 44 : 104);
            if (toolTipTop + height > window.innerHeight) {
                setTootipPostion('left');
                toolTipTop = rect.top - (height / 2) + 50;
                toolTipLeft = toolTipLeft - 315;
            }
        }
        setTop(toolTipTop);
        setLeft(toolTipLeft);
        setVisibility('visible');
    }

    const hideToolTip = () => {
        setVisibility('hidden');
        setTootipPostion('top');
    }

    if (ifTooltipAndTextLengthExceeded) {
        columnValue = <span>{text.substring(0, columnItem.charLength - 3) + '...'}</span>
        columnValue = <div className={`tooltip-wrapper ${tootipPosition}`} onMouseEnter={showToolTip} onMouseLeave={hideToolTip} onScroll={hideToolTip}>
            {columnValue}
            <span ref={tootipTextRef} className="tooltiptext" style={{ top: `${top}`, left: `${left}`, visibility: `${visibility}` }}>
                {text}
            </span>
        </div>
    }
    return columnValue;
}

const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return monthNames[month - 1] + ' ' + day + ',' + year;
}
const FilterComponent = ({ handleFilterClick, filterList, showFilterContainer, handleFilterIconClick, setActiveServiceCount }) => {
    const dispatch = useDispatch();
    const {
        snDashboardStatusList
    } = useSelector(
        (state) => ({
            snDashboardStatusList: snDashboardSelectors.getSnDashboardStatusList(state)
        }),
        shallowEqual
    );
    let filterRef = useRef();
    useEffect(() => {
        let filterHandler = (e) => {
            if (!filterRef.current.contains(e.target) && e.target.className !== 'filterable-heading')
                handleFilterIconClick(false)
        }
        document.addEventListener("mousedown", filterHandler);

        return () => {
            document.removeEventListener("mousedown", filterHandler)
        }
    })

    const handleClearClick = (e) => {
        const statusList = [...snDashboardStatusList];
        statusList.map(status => status.selected = false)
        dispatch(setSnDashboardStatusList(statusList));
    }
    const handleApplyClick = async (e) => {
        const statusList = snDashboardStatusList.filter(status => status.key !== 'all' && status.selected).map(status => status.key);
        const statusListForAnaltics = snDashboardStatusList.filter(status => status.selected).map(status => status.value);
        await sendAdobeAnaltics(null, null, "Status", "SORT_FILTER_CLICKS", "My Requests", "CTS_MY_REQUESTS", {
            filterTerm: statusListForAnaltics.join(" | "),
            filterLabel: "Status"
        })
        if (statusList.length === 0) {
            setActiveServiceCount(0);
            handleFilterIconClick(false);
            dispatch(setSnDashboardData({ ServiceRequests: [] }));
            return;
        }
        handleFilterClick(e, statusList);
    }
    const handleStatusClick = (event, data) => {
        const checkboxChecked = data.checked;
        const statusList = [...snDashboardStatusList];
        if (data.value === 'all') {
            statusList.map(status => status.selected = checkboxChecked)
        } else {
            statusList.map(status => {
                if (status.key === data.value)
                    status.selected = checkboxChecked
            })
            if (!checkboxChecked) {
                statusList[0].selected = false;
            } else {
                let allSelected = true;
                statusList.map(status => {
                    if (status.key !== 'all' && !status.selected) {
                        allSelected = false;
                    }
                })
                statusList[0].selected = allSelected;
            }
        }
        dispatch(setSnDashboardStatusList(statusList));
    };

    return (
        <span ref={filterRef} style={{ position: 'relative' }}>
            <Image className={showFilterContainer ? 'filter-icon rotate' : 'filter-icon'}
                src={GreenDownIcon} alt="GreenDownIcon"
                onClick={() => handleFilterIconClick()} />

            {showFilterContainer &&
                <div className='filter-container'>
                    <div className='filter-list-container'>
                        {snDashboardStatusList.map((status, index) => (
                            <div className="filter-item" key={index}>
                                <Checkbox className="filter-checkbox" label={status.value} value={status.key} checked={status.selected} onClick={handleStatusClick} />
                            </div>
                        ))}
                    </div>
                    <div className="filter-separator"></div>
                    <div className="filter-action-item">
                        <button className="btn secondary button" onClick={handleClearClick}>Clear</button>
                        <button className="btn primary button" onClick={handleApplyClick}>Apply</button>
                    </div>
                </div>}
        </span >

    )
}