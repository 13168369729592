// React
import React from 'react';
import { Layout } from "containers/Layout";
// React Routing
import { withRouter } from 'react-router-dom';
import CONFIG from 'config';
import LABELS from 'labels';
import Error from './Error';

// import styles
import './ErrorPage.scss';

/**
* @desc create error message 
* @returns
*/
export const ErrorPage = ({
  btnClick,
  btn2Click,
  errorCodeProp,
  match,
  error: oktaError
}) => {
  const { INSUFFICIENT_PERMISSIONS } = LABELS;
  const code = !!errorCodeProp ? errorCodeProp?.toLowerCase() : match?.params?.errorCode?.toLowerCase();
  if (oktaError) {
    console.error('OKTA login error', JSON.stringify(oktaError));
  }
  return (
    <Layout
      id="layout__error"
      layoutClass="layout__error"
      isErrorPage={true}
      pageTitle={INSUFFICIENT_PERMISSIONS}
    >
      <Error
        code={code}
        btnClick={btnClick}
        btn2Click={btn2Click}
      />
    </Layout>
  )
}

export default withRouter(ErrorPage);