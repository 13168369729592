import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getActiveUserSession } from "redux/actions/usersession/usersession.actions";
import { selectors as userSessionSelector } from "redux/reducers/usersession/usersession_reducer";
import { selectors as allServicesSelector } from "redux/reducers/allservices/allservices_reducer";
import { getBannersSuccess } from "redux/actions/allservices/allservices.actions";
import ReactHtmlParser from 'html-react-parser';
import Image from "components/shared/Image";
import InfoIcon from "assets/images/info-icon.svg";
import BannerClose from "assets/images/banner-close.svg";
import { ServiceNowDashboard } from "../../components/ServiceNowDashboard";
import "./MyRequests.scss";
import { sendAdobeAnaltics } from "utils/helpers/helpers";
import analytics from "../../utils/analytics/analytics";
import { PAGES } from "../../utils/analytics/analytics_constants";

import { Layout } from "containers/Layout";
const MyRequestsContainer = ({ banners, isBannersLoading, getBannersSuccess }) => {
    const closeBanner = () => {
        const newBanners = banners.filter(banner => banner.type !== 'myrequestbanner');
        getBannersSuccess(newBanners);
    }

    useEffect(async () => {
        await analytics.page.initialize({
            page: PAGES.CTS_MY_REQUESTS,
            trackPageLoad: true
        });
    }, []);

    return (
        <Layout id="chat" layoutClass="layout__chat-section" activeRoute="MyRequests">
            <div className="my-requests">
                {!isBannersLoading && banners.filter(banner => banner.type === 'myrequestbanner').length > 0 &&
                    <div class="banner-parent">
                        {banners.filter(banner => banner.type === 'myrequestbanner').map(banner => (
                            <>
                                <div class="banner-info-icon">
                                    <Image src={InfoIcon} alt="InfoIcon" />
                                </div>
                                <div class="banner-text">
                                    <div class="field-promotext">
                                        {ReactHtmlParser(banner.bannerText)}
                                    </div>
                                </div>
                                <div class="banner-close-icon">
                                    <Image src={BannerClose} alt="BannerClose" onClick={closeBanner} />
                                </div>
                            </>
                        ))}
                    </div>
                }
                <ServiceNowDashboard />
            </div>
        </Layout >
    );
};

const mapStateToProps = (state) => ({
    activeUserSessionId: userSessionSelector.getActiveUserSession(state),
    banners: allServicesSelector.getBanners(state),
    isBannersLoading: allServicesSelector.isBannersLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    getCurrentUserSession: () => dispatch(getActiveUserSession()),
    getBannersSuccess: (data) => dispatch(getBannersSuccess(data)),
});


export const MyRequests = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyRequestsContainer);

export { MyRequestsContainer };
