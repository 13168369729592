import { connectRouter, routerMiddleware } from "connected-react-router";
import { combineReducers } from "redux";
import { createStore } from "redux";
import history from "redux/history";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose } from "redux";

import {
  name as globalReducerName,
  reducer as globalReducer,
} from "./reducers/global/global_reducer";

import {
  name as notifyReducerName,
  reducer as notifyReducer,
} from "./reducers/notification/notification_reducer";

import {
  name as userSessionName,
  reducer as userSessionReducer,
} from "./reducers/usersession/usersession_reducer";

import {
  name as allServicesName,
  reducer as allServicesReducer,
} from "./reducers/allservices/allservices_reducer";

import {
  name as snDashboardName,
  reducer as snDashboardReducer,
} from "./reducers/sndashboard/sndashboard_reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  thunkMiddleware,
  sagaMiddleware,
  routerMiddleware(history),
  createLogger({ collapsed: true }),
];

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    [notifyReducerName]: notifyReducer,
    [globalReducerName]: globalReducer,
    [userSessionName]: userSessionReducer,
    [allServicesName]: allServicesReducer,
    [snDashboardName]: snDashboardReducer,
  }),
  composeEnhancers(applyMiddleware(...middleware))
);
const runSaga = sagaMiddleware.run;
export { store, runSaga };
