import { put, call, takeEvery } from 'redux-saga/effects';
import CONFIG from "config";
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import { SN_DASHBOARD, USER_SESSION } from "redux/constants";
import jwt_decode from "jwt-decode";
import authService from 'redux/auth';

import {
  setSnDashboardData,
  getSnDashboardFailure,
  getSnDashboardSuccess,
  getSnDashboardPending,
  snDashboardAPITakingTime,
  setSnDashboardStatusList
} from "./sndashboard.actions";

import {
  getActiveServicesCountSuccess
} from "../allservices/allservices.actions";

export function* getSnDashboardData(action) {
  const { payload: {
    snDashboardStatusList
  } } = action;
  try {
    const { X_API_KEY, API_URL: { GET_MY_REQUEST_API } } = CONFIG;
    yield put(getSnDashboardPending());
    const accessToken = authService.getAccessToken();
    const decodedToken = jwt_decode(accessToken);
    let hrId;
    if (decodedToken?.hrid) {
      hrId = decodedToken.hrid
    } else {
      hrId = '0';
    }
    const endpoint = GET_MY_REQUEST_API();
    let snDashboardData = yield call(doAxiosRequest, {
      method: 'post',
      endpoint: endpoint,
      headers: {
        'x-api-key': X_API_KEY
      },
      params: { "hrid": hrId, "status": snDashboardStatusList }
    });

    const isAPIWorking = (code) => {
      const successCodes = new Set([0, 204]);
      return successCodes.has(code);
    };
    snDashboardData.Errors.forEach(i => {
      if (i && !isAPIWorking(i.code)) {
        throw new Error('API is not working');
      }
    })
    yield put(getActiveServicesCountSuccess(snDashboardData.TicketCount));
    yield put(getSnDashboardSuccess(snDashboardData));

    const hrIDs = snDashboardData.ServiceRequests.filter(sr => sr.assignedToHrid).map(SR => SR.assignedToHrid);
    console.log("snDashboardStatusList: ", snDashboardStatusList);
    if (snDashboardStatusList.length === 0) {
      let StatusForUI = snDashboardData.StatusForUI;
      snDashboardData.StatusForUIArray = Object.keys(StatusForUI).sort().map(i => {
        return { key: i, value: StatusForUI[i], selected: i !== "cancelled" && i !== "completed" }
      });
      snDashboardData.StatusForUIArray.unshift({ key: "all", value: "All", selected: false });
      yield put(setSnDashboardStatusList(snDashboardData.StatusForUIArray));
    }
    yield call(callPeopleAPI, hrIDs, snDashboardData);
  } catch (error) {
    console.error("error: ", error);
    window.location.href = window.location.origin + '/404';
    yield put(getSnDashboardFailure(error?.message));
  }
}

const getSearchByIDQuery = (hrIDs) => {
  return `query {
    employees(ids: ${JSON.stringify(hrIDs)}) {
      id
      workEmail
      slackId
    }
  }`;
};

function* callPeopleAPI(hrIDs, snDashboardData) {
  try {
    const params = { query: getSearchByIDQuery(hrIDs) };
    const { PEOPLE_API_X_API_KEY, API_URL: { PEOPLE_API } } = CONFIG;
    let response = yield call(doAxiosRequest, {
      method: 'post',
      endpoint: PEOPLE_API,
      headers: {
        'x-api-key': PEOPLE_API_X_API_KEY
      },
      params
    });
    const employees = response.data.employees;
    const SRs = snDashboardData.ServiceRequests;
    employees.forEach(emp => {
      SRs.forEach(sr => {
        if (sr.assignedToHrid === emp.id) {
          sr.slackId = emp.slackId !== "NULL" ? emp.slackId : "";
          sr.email = emp.workEmail !== "NULL" ? emp.workEmail : "";
        }
      })
    })
    yield put(getSnDashboardSuccess(snDashboardData));
  } catch (error) {
    console.log(error)

  }
};

export default function* snDashboardSaga() {
  try {
    yield takeEvery(SN_DASHBOARD.GET_SN_DASHBOARD_DATA, getSnDashboardData);
  } catch (error) {
    yield put(moduleFailure(error, 'User Session Saga'));
  }
}