// import TaxonomySaga from "redux/actions/taxonomy/Taxonomy.saga.js";
import { put, all, call } from 'redux-saga/effects';
import { globalSagaFailure } from "./global.action";
import userSessionSaga from "redux/actions/usersession/usersession.saga";
import allServicesSaga from "redux/actions/allservices/allservices.saga";
import snDashboardSaga from "redux/actions/sndashboard/sndashboard.saga";

export default function* globalSaga() {
    try {
        yield all([
            call(userSessionSaga),
            call(allServicesSaga),
            call(snDashboardSaga),
        ]);
    } catch (err) {
        yield put(globalSagaFailure(err))
    }
}